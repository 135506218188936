<template>
  <b-card class="p-2 p-lg-4 w-100">
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Восстановление пароля
    </b-card-title>
    <div v-if="!emailWasSend">
      <b-card-text class="mb-2">
        Введите ваш e-mail и мы вышлем инструкции для смены пароля
      </b-card-text>

      <!-- form -->
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
      >

        <!-- email -->
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="email"
            v-validate="'required|email'"
            :state="email === '' ? null : !errors.has('email')"
            name="email"
            placeholder="john@example.com"
          />
          <small
            v-show="errors.has('email')"
            class="text-danger"
          >{{ errors.first('email') }}</small>
        </b-form-group>

        <div class="row mt-3">
          <div class="col-12 col-lg-6">
            <b-button
              class="w-100"
              variant="outline-primary"
              block
              :to="{name: 'login'}"
            >
              Войти
            </b-button>
          </div>
          <div class="col-12 col-lg-6">
            <!-- submit buttons -->
            <b-button
              class="w-100"
              type="submit"
              variant="primary"
              block
              @click="recoveryPassword"
            >
              Восстановить пароль
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
    <div v-else>
      <b-card-text class="mb-2">
        Мы выслали инструкцию для смены пароля на почту: {{ email }}
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BCardText, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import Auth from '@/api/http/models/auth/Auth'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
  },
  mixins: [mixinErrorsParse],
  data() {
    return {
      email: '',
      emailWasSend: false,
    }
  },
  computed: {
    ...mapGetters({
      userIsVerified: 'user/isEmailVerified',
    }),
  },
  methods: {
    ...mapActions({
      fetchCurrentUser: 'user/FETCH_CURRENT_USER',
    }),
    async recoveryPassword() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Auth.forgot({
          email: this.email,
        })

        if (response.status === 'success') {
          this.emailWasSend = true
        } else {
          this.errors.add({
            field: 'email',
            msg: 'Неверный адрес электронной почты',
          })
        }
      }
    },
  },
}
</script>
